import React from 'react'
import styled from 'styled-components'

const Innovations = () => {
    return (
        <StyledInnovations>
            <TextWrapper>
                <H2>INNOVATIONS</H2>
                <Ul>
                    <Li>
                        <H4>New Generation<br /> Battery Power<br/> Management</H4>
                        <P>Medad Technology is building EV batteries with the ability to govern the power flow and
                            consumption of each battery cell. The new batteries will solve the issues of EV slow
                            adoption: safety issues, low range and long charging time.</P>
                    </Li>
                    <Li>
                        <H4>Battery Temperature<br/> Control</H4>
                        <P>EV batteries face operating conditions which can result in big temperature differences
                            between different cells. Poor load balancing can produce large temperature gradients across
                            the battery. Medad Technology’s power management system can help reduce these temperature
                            imbalances, leading to safer operations and extended battery life.</P>
                    </Li>
                    <Li>
                        <H4>Better Low <br />Temperature Response</H4>
                        <P>Studies show battery life can be reduced by up to 41% at temperatures of 20°F and with the
                            car’s heater being used. Improved power cell management can optimize the current flow
                            through the battery to balance the power supply and help the battery reach and maintain the
                            correct operating temperature, even in low-temperature conditions.</P>
                    </Li>
                </Ul>
            </TextWrapper>
        </StyledInnovations>
    )
}

export default Innovations

const StyledInnovations = styled.div`
  padding: 7rem 12rem 7rem 12rem;
  background-color: #E7F0F3;
  color: #0C2637;
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 4.5rem 2.4rem 4.5rem 2.4rem;
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 7rem 15vw 7rem 15vw;
  }
`

const TextWrapper = styled.div`
`

const H2 = styled.h2`
  font-family: Sequel85;
  font-size: 3.2rem;
  line-height: 5.8rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-family: Sequel75;
    font-size: 2rem;
    line-height: 2.8rem;
  }
`

const Ul = styled.ul`
  list-style: none;
  margin-top: 6rem;
  display: flex;
  gap: 12.4rem;
  
  &:first-child:nth-child(2):last-child {
    h4 {
      width: 20ch;
    }
  }
  
  @media ${({theme}) => theme.breakpoints.mobile} {
    flex-direction: column;
    margin-top: 3rem;
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
    &:nth-child(2):last-child {
      h4 {
        width: 13ch;
      }
    }
    &:nth-child(3) {
      h4 {
        width: 4ch;
      }
    }
  }
`

const Li = styled.li`
    flex: 1;
`

const H4 = styled.h4`
  font-family: Sequel75;
  font-weight: lighter;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #3B869D;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    br {
      display: none;
    }
  }
`

const P = styled.p`
  margin-top: 2rem;
  font-family: Sequel45;
  font-size: 1.6rem;
  line-height: 2.2rem;
`
