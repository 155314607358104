import { graphql, useStaticQuery } from "gatsby";
import React from 'react'

import Hero from "../../components/powermanagement-page/hero/Hero.js";
import Layout from '../../components/layout/Layout'
import Seo from '../../components/seo'
import Sonochemistry from "../../components/healthtech-page/sonochemistry/Sonochemistry";
import Applications from "../../components/healthtech-page/applications/Applications";
import Security from "../../components/healthtech-page/security/Security";
import OtherSectors from "../../components/healthtech-page/other-sectors/OtherSectors";
import Electric from "../../components/powermanagement-page/electric/Electric";
import Innovations from "../../components/powermanagement-page/innovations/Innovations";

const PowerManagementPage = (props) => {
    const {strapiPowerManagement} = useStaticQuery(query);
    return (
        <Layout>
            <Seo
                title={strapiPowerManagement.metaTitle}
                description={strapiPowerManagement.metaDescription}
                keywords={strapiPowerManagement.metaKeywords}
            />
            <Hero path={props.location.pathname}/>
            <Electric />
            <Innovations />
            <OtherSectors hiddenIndex={4} />
        </Layout>
    )
}

export default PowerManagementPage

const query = graphql`
    query {
        strapiPowerManagement {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`
