import React, {useState} from 'react'
import styled from 'styled-components'

const Electric = () => {
    const [readMore, setReadMore] = useState(false)

    return (
        <StyledElectric>
            <TextWrapper>
                <H3>Medad Technology is designing a new Systems on Chip (SoC) that delivers higher safety and performance of electric vehicles batteries, with an aim to accelerate the transition to a carbon-free transportation sector.</H3>
                <Categories>
                    <P>Electric vehicle batteries are one of the most critical components of an EV but also a limiting factor in EV performance. EV batteries are made of 100s of individual cells and the power draw is not equal across every cell. Differences in power draw, cell depletion, and operating temperature can affect the life of the battery and its safe operation, creating real safety risks and significant usage inconveniences due to long charging hours and low distance range.</P>
                    <ShowMoreButton showMore={readMore} onClick={() => setReadMore(true)}>Show more</ShowMoreButton>
                    <ShowMore showMore={readMore}>
                        <P>Medad Technology is researching extensively the field of load-balancing battery cells. We have designed integrated circuit (IC) chips to govern the power flow and consumption of each battery cell. These IC chips provide data and control the power flow within the battery and its cells.</P>
                        <P>Medad Technology’s goal in Power Management is to take part in accelerating the transition into a  carbon-free transportation sector. Confronting climate change, improving the safety of EV batteries, and reducing the cost of new technologies are all ways Medad Technology hopes to improve public health and the quality of life for people all around the world.</P>
                    </ShowMore>
                </Categories>
            </TextWrapper>
        </StyledElectric>
    )
}

export default Electric

const StyledElectric = styled.div`
  padding: 9.4rem 12rem 9.4rem 12rem;
  background: rgb(255, 255, 255);
  background: linear-gradient(225deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.05) 69%, rgba(149, 144, 217, 0.5) 99%);
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 6rem 2.4rem 6rem 2.4rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(315deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.05) 69%, rgba(149, 144, 217, 0.5) 99%);
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 9.4rem 15vw 9.4rem 15vw;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Categories = styled.div`
  margin-top: 4rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 2rem;
  }

  &:first-child {
    margin-top: 0;
  }
`

const ShowMoreButton = styled.button`
  margin-top: 2rem;
  display: none;
  text-decoration: none;
  border: none;
  background: none;
  color: #3B869D;
  font-family: Sequel45;
  font-size: 1.6rem;
  line-height: 2.2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: ${({ showMore }) => showMore ? 'none' : 'block'};
  }
`

const ShowMore = styled.div`
    @media ${({ theme }) => theme.breakpoints.mobile} {
      display: ${({ showMore }) => showMore ? 'block' : 'none'};
    }
`

const H3 = styled.h3`
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 4.4rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`

const P = styled.p`
  margin-top: 2.4rem;
  font-family: Sequel45;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`
